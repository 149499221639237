

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { Context } from '@nuxt/types';
import moment from 'moment';
import { ResponseCode } from '~/util/constant';
import { gaodeKey, message } from '~/util/common';
// @ts-ignore
import vueQr from 'vue-qr';
import _ from 'lodash';
import { formRules } from './validate';
import payMixin from './pay-mixin';
import {
  requiredBlur,
  requiredChange,
  validMobile,
  validEmail,
  validIDCard,
} from '@/util/validate';
import { serviceObj, serviceTicket } from '~/pages/fair/service';
import {
  ageRangeOption,
  userTypeOption,
  defaultAirTicketSearch,
  defaultAirTicketObj,
} from './helper';

export default Vue.extend<any, any, any>({
  // layout: 'defaultVisa',
  components: { vueQr },
  filters: {
    handleDate(e: any) {
      console.log(984, e);
      return e ? moment(e).format('MM月DD日') : '';
    },
    handleWeek(e: any) {
      let num: any = moment(e).format('E');
      var week = ['一', '二', '三', '四', '五', '六', '日'];
      return e ? `周${week[num - 1]}` : '';
    },
    // 价格"分"转为"元"
    handlePrice(e: string | number) {
      return Math.divide(Number(e), 100).toFixed(0);
    },
    handleDiscount(e: any) {
      let res = Math.divide(Number(e), 100).toFixed(1);
      let str = Number(res) < 10 ? `${res}折` : '';
      return str;
    },
  },
  mixins: [payMixin],
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   if (path === '/information' || path === '/information/') {
  //     return true;
  //   }
  //   const _validate = /information-(\d+)-(\d+)-(\d+)/.test(path);
  //   return _validate;
  // },
  async asyncData(ctx) {
    return {};
  },
  data(): Record<string, any> {
    // 手机号
    let validatorPhone = (rule: any, value: any, callback: any) => {
      if (value === '') {
        return callback(new Error('请输入'));
      } else {
        if (!validMobile(value)) {
          return callback(new Error('格式错误'));
        } else {
          callback();
        }
      }
    };
    // 邮箱
    let validatorEmail = (rule: any, value: any, callback: any) => {
      if (value == '') {
        return callback(new Error('请输入'));
      } else {
        if (!validEmail(value)) {
          return callback(new Error('格式错误'));
        } else {
          callback();
        }
      }
    };
    return {
      ageRangeOption,
      userTypeOption,
      areaProps: {
        label: 'name',
        value: 'name',
        children: 'districts',
      },

      areaData: [],
      mailType: 1,
      pickerOptions: {
        disabledDate: (time: any) => {
          // 禁用今天之后的日期
          // 禁用未来10天内的日期 10 * 24 * 60 * 60 * 1000
          // return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
          // console.log(328, time.getTime());
          return time.getTime() > new Date().getTime(); // - 24 * 60 * 60 * 1000;
        },
      },

      ruleForm: {
        airlineCompany: '',
        arrAirport: '',
        channel: '',
        depAirport: '',
        departureTime: '',
        flightNo: '',
        payType: '',
        flightCreateOrderRequest: {
          arrCityCode: '',
          autoPay: '',
          buyerUniqueKey: '',
          contactInfo: {
            contactName: '',
            contactPhone: '',
            contactEmail: '',
          },
          depCityCode: '',
          depDate: '',
          orderParams: '',
          otaItemId: '',
          price: '',
          travelerInfoList: [
            {
              certNo: '',
              certType: 0,
              name: '',
              birthday: '',
              outUserId: '',
              phone: '',
              type: '',
            },
          ],
          tripType: '',
        },
        //
        planTime: '',
        materialCloseTime: '',
        buyerMsg: '',
        visaBuyers: {
          ageRange: '',
          firstName: '',
          idEffectiveTime: '',
          idNo: '',
          lastName: '',
          name: '',
          passportEffectiveTime: '',
          passportNo: '',
          userType: '',
        },
        contact: '',
        phone: '',
        area: null,
        visaReciveAdress: {
          city: '',
          detailAddress: '',
          district: '',
          location: '',
          name: '',
          phone: '',
          province: '',
        },
      },

      rules: {
        ...formRules,
      },
      remark: '',
      payType: 1,
      // activeStep: 'pay',
      aliform: null,
      showQrcode: false,
      code: {} as any,
      loadingPay: false,
      visaInfo: JSON.parse(JSON.stringify(defaultAirTicketObj)),

      // 机票

      cardTypeEnum: {
        0: '身份证',
        // 1: '护照',
        // 6: '港澳台胞',
        // 5: '台胞证',
      },
      passengerTypeEnum: {
        ADULT: '成人(12周岁以上)',
        CHILD: '儿童(2-12周岁)',
        INFANT: '婴儿(0-2周岁)',
      },
      activeStep: 'order', // order, pay
      travelerInfoList: [
        {
          certNo: '',
          certType: '0',
          name: '',
          birthday: '',
          outUserId: '',
          phone: '',
          type: 'ADULT',
        },
      ],
      contactInfo: {
        contactName: '',
        contactPhone: '',
        contactEmail: '',
      },
      contactInfoTest: {
        contactName: '',
        contactPhone: '',
      },
      travelerInfoTest: {
        certNo: '',
        certType: '0',
        name: '',
        birthday: '',
        outUserId: '',
        phone: '',
        type: 'ADULT',
      },
      contactInfoRules: {
        contactName: [requiredBlur],
        contactPhone: {
          required: true,
          validator: validatorPhone,
          trigger: 'blur',
        },
        contactEmail: {
          required: true,
          validator: validatorEmail,
          trigger: 'blur',
        },
      },
      searchInfo: JSON.parse(JSON.stringify(defaultAirTicketSearch)),
      flightInfo: JSON.parse(JSON.stringify(defaultAirTicketObj)),
      cabinInfo: JSON.parse(
        JSON.stringify(defaultAirTicketObj.flightCabinInfoList[0])
      ),
    };
  },
  computed: {
    totalPrice() {
      let price = Math.divide(Number(this.cabinInfo.totalPrice), 100).toFixed(
        0
      );
      let num = this.travelerInfoList.length;
      return Math.multiply(Number(price), num).toFixed(2);
    },
  },
  created() {
    this.getAirTicketInfo();

    // console.log(1178, getSearchInfo, flightInfo, cabinInfo);
    // searchInfo
    // (document.getElementsByTagName('header') as any)[0].style.display = 'none';
  },
  mounted() {},
  methods: {
    handleSuccess() {
      this.$router.push({
        path: '/airTicket/payResult/success',
      });
    },
    nameChange(e: any, index: number) {
      if (e && index == 0) {
        this.contactInfo.contactName = e;
      }
    },
    phoneChange(e: any, index: number) {
      if (e && index == 0) {
        this.contactInfo.contactPhone = e;
      }
    },
    certNoBlur(e: any, str: string, index: number) {
      console.log(str, index);
      // this.travelerInfoList
      // ADULT: '成人(12周岁以上)',
      //   CHILD: '儿童(2-12周岁)',
      //   INFANT: '婴儿(0-2周岁)',
      if (validIDCard(str)) {
        let y = str.slice(6, 10);
        let m = str.slice(10, 12);
        let d = str.slice(12, 14);
        this.travelerInfoList[index]['birthday'] = `${y}-${m}-${d}`;
        //
        let yy = moment().format('YYYY');
        let age = Number(yy) - Number(y);
        if (age <= 2) this.travelerInfoList[index]['type'] = 'INFANT';
        if (age > 2 && age < 13) this.travelerInfoList[index]['type'] = 'CHILD';
        if (age > 12) this.travelerInfoList[index]['type'] = 'ADULT';
      } else {
        message.call(this, {
          delay: 3000,
          title: '身份证号输入有误',
          type: 'error',
        });
      }
    },
    /**
     * 获取选中的机票的信息
     * @returns {any}
     */
    getAirTicketInfo() {
      // if (this.$route.query.hasOwnProperty('debug')) {
      for (let i in this.contactInfo) {
        this.contactInfo[i] = this.contactInfoTest[i];
      }
      for (let i in this.travelerInfoList[0]) {
        this.travelerInfoList[0][i] = this.travelerInfoTest[i];
      }
      // }
      let searchInfo = this.$store.state.airTicket.searchInfo;
      for (let i in this.searchInfo) {
        if (searchInfo[i]) this.searchInfo[i] = searchInfo[i];
      }
      let flightInfo = this.$store.state.airTicket.flightInfo;
      for (let i in this.flightInfo) {
        if (flightInfo[i]) this.flightInfo[i] = flightInfo[i];
      }
      let cabinInfo = this.$store.state.airTicket.cabinInfo;
      for (let i in this.cabinInfo) {
        if (cabinInfo[i]) this.cabinInfo[i] = cabinInfo[i];
      }
      if (!this.flightInfo.flightType) {
        this.$router.back();
      }
    },

    addPeople() {
      if (!this.$route.query.hasOwnProperty('debug')) {
        this.travelerInfoList.push({
          certNo: '',
          certType: '0',
          name: '',
          birthday: '',
          outUserId: '',
          phone: '',
          type: 'ADULT',
          key: Date.now(),
        });
      }

      if (this.$route.query.hasOwnProperty('debug')) {
        let result: any = {
          certNo: '',
          certType: '',
          name: '',
          outUserId: '',
          phone: '',
          type: '',
          key: Date.now(),
        };
        for (let i in this.travelerInfoTest) {
          result[i] = this.travelerInfoTest[i];
        }
        this.travelerInfoList.push(result);
      }
    },
    removePeople(item: any) {
      var index = this.travelerInfoList.indexOf(item);
      if (this.travelerInfoList.length > 1 && index !== -1) {
        this.travelerInfoList.splice(index, 1);
      }
    },
    birthdayChange(e: string, index: number) {
      let yy = moment().format('YYYY');

      let y = e.slice(0, 4);

      let age = Number(yy) - Number(y);
      let ageValue = '';
      if (age <= 2) ageValue = 'INFANT';
      if (age > 2 && age < 13) ageValue = 'CHILD';
      if (age > 12) ageValue = 'ADULT';
      this.travelerInfoList[index]['type'] = ageValue;
    },
    async checkInfo() {
      let status = true;
      let message = '';
      for (let i of this.travelerInfoList) {
        if (!i.name) {
          status = false;
          message = '请输入姓名';
          break;
        }
        //

        if (!i.certNo) {
          status = false;
          message = '请输入证件号码';
          break;
        }
        if (i.certType == 0 && !validIDCard(i.certNo)) {
          status = false;
          message = '身份证号输入有误';
          break;
        }
        // 检查重复的证件号码
        let certNoList = this.travelerInfoList.filter(
          (x: any) => x.certType == i.certType && x.certNo == i.certNo
        );
        if (certNoList.length > 1) {
          status = false;
          message = `相同类型证件，存在重复证件号码`;
          break;
        }

        if (!i.birthday) {
          status = false;
          message = '请选择出生日期';
          break;
        }
        if (!i.phone) {
          status = false;
          message = '请输入手机号';
          break;
        }
        if (!validMobile(i.phone)) {
          status = false;
          message = '手机号格式错误';
          break;
        }
        // 如果选的是身份证, 比对出生日期与身份证中的出生日期,不一致则提示

        let dateStr = i.birthday.replace(/-/g, '');

        if (
          i.certType == 0 &&
          i.birthday &&
          i.certNo.includes(dateStr) == false
        ) {
          status = false;
          message = '出生日期与身份证中的出生年月日不一致';
          break;
        }
        //
        if (i.certType == 0) {
          let IdCardResult = await this.checkIdCard(i.certNo, i.name);
          // let IdCardResult = this.$axios.post('/api/idApi/check', {
          //   visitorIdentity: i.certNo,
          //   visitorName: i.name,
          // });

          if (IdCardResult.code != 1) {
            status = false;
            message = IdCardResult.errorMessage;
            break;
          }
        }
      }
      let result = {
        status,
        message,
      };

      return result;
    },
    async checkIdCard(certNo: string, name: string) {
      let result = await this.$axios.post('/api/idApi/check', {
        visitorIdentity: certNo,
        visitorName: name,
      });
      return result;
    },

    async submitForm(formName: any) {
      // this.activeStep = 'pay';
      // return false;
      //
      let checkResult = await this.checkInfo();

      if (!checkResult.status) {
        // this.$message.error(checkResult.message);
        message.call(this, {
          delay: 3000,
          title: checkResult.message,
          type: 'error',
        });
        return false;
      }

      this.$store.commit('airTicket/setTravelerInfo', [
        ...this.travelerInfoList,
      ]);

      this.$refs[formName].validate((valid: any) => {
        if (valid) {
          this.activeStep = 'pay';
          // this.$router.replace({
          //   path: '/visa/submitOrder',
          //   query: {
          //     ...this.$route.query,
          //     step: 'pay',
          //   },
          // });
        } else {
          return false;
        }
      });
    },
    back() {
      this.activeStep = 'order';
    },
    togglePayment(type: number) {
      this.payType = type;
      // if (type === 'alipay') {
      //   this.$data.showBtn = true;
      // }
    },
  },
  head() {
    const { pageNum } = this.$data;
    return {
      title: `展会资讯_新闻_攻略_报道${
        pageNum > 1 ? `_第${pageNum}页` : ''
      }-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: `聚展网是国内专业国际展会一站式服务平台,提供最新最全的${new Date().getFullYear()}年国际展会咨询,新闻报道,出行攻略,搭建资讯,全球展会信息平台,国外展会信息,免费咨询。`,
        },
      ],
    };
  },
});
