import { render, staticRenderFns } from "./submitOrder.vue?vue&type=template&id=c955d586&scoped=true&"
import script from "./submitOrder.vue?vue&type=script&lang=ts&"
export * from "./submitOrder.vue?vue&type=script&lang=ts&"
import style0 from "./submitOrder.vue?vue&type=style&index=0&id=c955d586&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c955d586",
  null
  
)

export default component.exports