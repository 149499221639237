




















































































































































































































































































































































































































































































































































































































import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import Vue from 'vue';
import TopBg from '../fair/component/detailTopBg.vue';
import { scaleEnum } from './helper';
import { ResponseCode } from '~/util/constant';
import { message } from '~/util/common';
import { bucketCdnHost, bucketHost } from '~/util/common';
// import { serviceObj } from '~/pages/fair/service';
import { TraceCode } from '~/util/trace';
import JuMap from '~/components/Map/index.vue';

export default Vue.extend({
  name: 'Booth',
  layout: 'layout2',
  // 校验params参数
  validate(ctx) {
    const id = _.get(ctx, 'params.id', null);
    return /^\d+$/.test(id);
  },
  components: {
    TopBg,
    JuMap,
  },
  async asyncData(ctx) {
    const { type = 'booth' } = ctx.query;
    const id = _.get(ctx, 'params.id', null);

    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${id}`),
      ctx.$axios.get(`/api/exhibition/boothInfo/${id}`),
    ]);

    const [fairRes, boothRes] = res;

    if (fairRes.code === ResponseCode.BLACK_LOGIN) {
      return ctx.error({
        message: '先登录！',
        path: ctx.route.fullPath,
        statusCode: 504,
      });
    }

    let fair: Record<string, any> = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      if (!fairRes.data) {
        return ctx.error({
          message: '展会不存在！',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
      // online 1:上架 2:未上架
        if (
          (!_.isNil(fairRes.data.online) && fairRes.data.online !== 1 && id !== '4') ||
          _.isNil(fairRes.data)
        ) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }

      if (fairRes.data.boothInfo) {
        fairRes.data.boothInfo = JSON.parse(fairRes.data.boothInfo);
      } else {
        fairRes.data.boothInfo = [];
      }

      fair = fairRes.data || {};
      fair.boothPrice1 = '';
      fair.boothPrice2 = '';
      if (fair.boothInfo && fair.boothInfo.length > 0) {
        fair.boothInfo.forEach((item: any) => {
          if (item.priceType === '标准展位') {
            fair.boothPrice1 = item.price;
          }
          if (item.priceType === '光地展位') {
            fair.boothPrice2 = item.price;
          }
        });
      }
    }

    // 展会价格
    let booth = {};
    if (boothRes.code === ResponseCode.SUCCESS) {
      booth = boothRes.data;
    }
    return {
      fair,
      booth,
      type,
      attribution: `&copy; <span>${
        _.get(fair, 'pavilionInfo.pavilionName', null) ||
        _.get(fair, 'pavilionInfo.pavilionEnglishName', null)
      }</span>`,
      center: [
        _.get(fair, 'pavilionInfo.latitude', 0),
        _.get(fair, 'pavilionInfo.longitude', 0),
      ],
    };
  },
  data(): Record<string, any> {
    // const checkEmail = (
    //   _rule: any,
    //   value: string,
    //   callback: (msg?: string) => void
    // ) => {
    //   const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    //   if (!mailReg.test(value)) {
    //     callback('请输入正确的邮箱格式');
    //   } else {
    //     callback();
    //   }
    // };

    // const checkTel = (
    //   _rule: any,
    //   value: string,
    //   callback: (msg?: string) => void
    // ) => {
    //   const telReg = /^[\d]{7,16}$/;
    //   if (!telReg.test(value)) {
    //     callback('请输入正确的电话格式');
    //   } else {
    //     callback();
    //   }
    // };

    return {
      ruleForm: {
        companyName: '',
        linkman: '',
        remark: '',
        email: '',
        boothType: undefined,
        linkmanPhoneNumber: '',
        content: '',
        scale: undefined,
        scale1: undefined,
        peopleNum: undefined,
      },
      rules: {
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        linkman: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
        ],
        // email: [
        //   { required: true, message: '邮箱不能为空', trigger: 'blur' },
        //   { type: 'email', message: '请输入正确的邮箱', trigger: 'blur' },
        // ],
        boothType: [
          { required: true, message: '价格不能为空', trigger: 'blur' },
        ],
        linkmanPhoneNumber: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '展品内容不能为空', trigger: 'blur' },
        ],
        scale: [
          { required: true, message: '展位面积不能为空', trigger: 'blur' },
        ],
        scale1: [
          { required: true, message: '展位面积不能为空', trigger: 'blur' },
          {
            type: 'number',
            min: 1,
            message: '请输入合理的展位面积',
            trigger: 'change',
          },
        ],
        peopleNum: [
          { required: true, message: '人数不能为空', trigger: 'blur' },
        ],
      },
      fair: {},
      booth: {},
      isLoading: false,
      scaleEnum,
      cooperateTypeList: [
        {
          value: 11,
          label: '酒店预订服务',
          check: false,
          url: require('@/assets/images/apply_jiudian.jpg'),
        },
        {
          value: 1,
          label: '签证办理服务',
          check: false,
          url: require('@/assets/images/apply_qianzheng.jpg'),
        },
        {
          value: 10,
          label: '机票预订服务',
          check: false,
          url: require('@/assets/images/apply_jipiao.jpg'),
        },
        {
          value: 2,
          label: '行程定制服务',
          check: false,
          url: require('@/assets/images/apply_zhanwei.jpg'),
        },
        {
          value: 4,
          label: '特装展位设计及搭建服务',
          check: false,
          url: require('~/assets/images/apply_dajian.jpg'),
        },
        {
          value: 12,
          label: '申请展位',
          check: false,
          url: require('~/assets/images/apply_zhanweishenqi.jpg'),
        },
      ],
      mark: '',
      serviceList: [],
      mapDialogShow: false,
      boothServiceList: []
    };
  },
  filters: {
    industry(data: string): string {
      try {
        return data.split(',')[0] + '展会';
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + '展会';
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  computed: {
    title(): string {
      let t = '展位';
      if (this.type === 'booth') {
        t = '展位';
      } else if (this.type === 'card') {
        t = '邀请函';
      } else if (this.type === 'build') {
        t = '展位搭建';
      }
      return t;
    },
  },
  created() {
    // console.log(this.$route.query.success);
    if (this.$route.query.success) {
      this.mark = this.$route.query.success;
    }
    if (process.client) {
      const boothForm = sessionStorage.getItem('boothForm');
      try {
        this.ruleForm = JSON.parse(boothForm as any) || {};
      } catch (error) {
        console.error(error);
      }
      if (boothForm) {
        sessionStorage.removeItem('boothForm');
      }
    }
    
  },
  mounted() {
    this.getService()
    const phone = this.$cookies.get('phoneNumber') || '';
    if (phone) {
      this.$set(this.ruleForm, 'linkmanPhoneNumber', phone);
    }
    const industry = this.fair.firstIndustryId;

    // this.serviceList = serviceObj[industry]?.service || [];
    // if (this.fair.industry === '消费电子') {
    //   Vue.set(this, 'serviceList', this.serviceList.slice(1));
    // } else {
    //   Vue.set(
    //     this,
    //     'serviceList',
    //     this.serviceList.slice(0, 1).concat(this.serviceList.slice(2))
    //   );
    // }
  },
  methods: {
    // 获取客服
    async getService() {
      console.log(2139,this.fair)
      // fair.industry1
      
      const res: any = await this.$axios.post(`/api/hr/staff/services`, {
        exhibitionId: this.fair.exhibitionId,
        industry: this.fair.industry1,
        skillCodes: ["exOnlineSale"]
      });
      if (res.code === ResponseCode.SUCCESS) {
        console.log("获取客服",res)
        let list = res.data
        list.map((item:any) => {
          item.avatar = `${item.avatar}?x-oss-process=image/auto-orient,1/interlace,1/resize,m_fill,w_70,h_70/sharpen,100`
        })
        this.boothServiceList = res.data || []
        
      }
    },
    getType(data: any) {
      data.check = !data.check;
    },
    getAddress() {
      this.mapDialogShow = true;
    },
    submitForm(formName: string) {
      // const token = this.$cookies.get('access_token');
      // if (!token) {
      //   await this.$store.commit(
      //     'user/setOrigins',
      //     location.origin + this.$route.fullPath
      //   );
      //   await this.$store.commit('user/setAction', 1);
      //   await this.$store.commit('user/setIndustryId', this.fair.industry1);
      //
      //   this.$login.show();
      //   return;
      // }
      const $form = this.$refs[formName] as ElForm;
      $form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.handleApplyBooth();
        } else {
          return false;
        }
      });
    },
    async handleApplyBooth() {
      // let serviceTypeList: any = [];
      // this.cooperateTypeList.forEach((item: any) => {
      //   if (item.check) {
      //     serviceTypeList.push(item.value);
      //   }
      // });
      const { id } = this.$route.params;
      const res: any = await this.$axios.post('/api/exhibition/applyBooth', {
        ...this.ruleForm,
        exhibitionId: +id,
        promotionLink: window.location.href,
      });
      this.isLoading = false;
      if (res.code === ResponseCode.SUCCESS) {
        const $form = this.$refs.rule as ElForm;
        $form?.resetFields();
        await message.call(this, {
          delay: 3000,
          type: 'success',
          title: '展位申请成功',
          msg: '专业客服将在1-2个工作日内电话与您联系。',
        });
        this.$router.push(`/exhibition/${id}.html`);
        // this.$message.success('展位申请成功.');
        // 埋点
        window.trace?.upload({
          module: TraceCode.MO_FAIR_BOOTH,
          callbackCode: TraceCode.CC_SUBMIT_BOOTH,
          bizId: this.$route.params.id,
          success: TraceCode.SUCCESS,
        });
      } else {
        message.call(this, {
            delay: 3000,
            title: res.errorMessage,
            type: 'error',
        });
        // 埋点
        window.trace?.upload({
          module: TraceCode.MO_FAIR_BOOTH,
          callbackCode: TraceCode.CC_SUBMIT_BOOTH,
          bizId: this.$route.params.id,
          success: TraceCode.FAIL,
        });
      }
    },
    querySearchAsync: _.debounce(async function (
      str: string,
      cb: (val: any[]) => void
    ) {
      // @ts-ignore
      const { $axios } = this;
      let data = [];
      if (str && str.trim()) {
        const res: any = await $axios.post(
          '/api/companyApi/keyWord',
          str.trim(),
          {
            headers: {
              'Content-Type': 'text/plain',
            },
          }
        );
        data = res.data.map((r: string) => ({ value: r }));
      } else {
        data = [];
      }
      cb(data);
    },
    300),
  },
  head(): Record<string, any> {
    const { exhibitionName, area } = this.$data.fair;
    let title = `${exhibitionName}_展位预订-聚展`;
    let keywords = '';
    let description = '';
    if (this.type === 'card') {
      // 邀请函
      title = `${exhibitionName}商务签证邀请函_办理_申请_价格-聚展`;
      keywords = `${area}商务邀请函,${area}签证邀请函如何办理,如何申请${area}邀请函`;
      description = `聚展网专业办理${area}签证邀请函、商务邀请函、为用户提供专业的展会服务`;
    } else if (this.type === 'build') {
      // 展位搭建
      title = `${exhibitionName}展览会展位搭建_特装搭建_设计_公司_价格-聚展`;
      keywords = `${area}展位搭建,搭建设计,搭建方案`;
      description = `聚展为境外展会提供专业的展位搭建服务,包含展位设计、搭建施工、搭建效果、搭建报价`;
    }
    return {
      title,
      meta: [
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
    };
  },
});
